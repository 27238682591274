import { Injectable } from '@angular/core';
import { Auth, FacebookAuthProvider, GoogleAuthProvider, onAuthStateChanged, signInWithPopup, signOut } from '@angular/fire/auth';
import { doc, docData, Firestore, setDoc } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private currentUserData = null;
  logout$: Subject<boolean> = new Subject<boolean>();

  constructor(private auth: Auth, private firestore: Firestore, private router: Router) {
    onAuthStateChanged(this.auth, user => {      
      if (user) {
        const userDoc = doc(this.firestore, `users/${user.uid}`);
        docData(userDoc, { idField: 'id' }).pipe(
          takeUntil(this.logout$)
        ).subscribe(data => {
          this.currentUserData = data;
        })
      } else {
        this.currentUserData = null;
      }
    })
  }

  // Sign in with Gmail
  GoogleAuth() {
    return this.AuthLogin(new GoogleAuthProvider());
  }

  // Sign in with Facebook
  FacebookAuth() {
    return this.AuthLogin(new FacebookAuthProvider());
  }
  
  // Auth providers
  AuthLogin(provider) {
    return signInWithPopup(this.auth, provider)
      .then((result) => {
        this.router.navigate(['dashboard']);
        this.SetUserData(result.user);
      })
      .catch((error) => {
        window.alert(error);
      });
  }

    // Store user in localStorage
    async SetUserData(user) {
      const userDoc = doc(this.firestore, `users/${user.uid}`);
      const userData = {
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
        photoURL: user.photoURL,
        emailVerified: user.emailVerified,
      };
      await setDoc(userDoc, userData);
    }

  async logout() {
    await signOut(this.auth);
    this.logout$.next(true);

    this.router.navigateByUrl('/', {replaceUrl: true});
  }
  
}
