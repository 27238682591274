import { Injectable } from '@angular/core';
import { Auth, onAuthStateChanged } from '@angular/fire/auth';
import { collection, doc, Firestore, getDoc, increment, onSnapshot, query, serverTimestamp, where } from '@angular/fire/firestore';
import { arrayUnion, setDoc } from '@firebase/firestore';
import { promise } from 'protractor';
import { of, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  user = null;
  logout$: Subject<boolean> = new Subject<boolean>();
  
  userInfo = null;
  userInfo$: Subject<{}> = new Subject<{}>();

  constructor(
    private firestore: Firestore,
    private firebaseAuth: Auth
  ) {

    onAuthStateChanged(this.firebaseAuth, user => {

      if (!user) {
        this.user = null;
        this.logout$.next(true);
      } else {

        this.user = user;

        const unsub = onSnapshot(doc(this.firestore, `users/${user.uid}`), (doc) => {
          console.log('got user doc')
          this.userInfo = doc.data();
          this.userInfo$.next(doc.data());
        })

      }

    })
    
  }

  getAvatarUrl() {
    return this.userInfo?.photoUrl
  }

  passcodeRedeemed(passcode) {

    const passcodeDocRef = doc(this.firestore, `passcodes/${passcode.id}`)
    const passcodeData = { status: 'valid', redeemed: increment(1), lastRedeemed: serverTimestamp()  }
    const passcodePromise = setDoc(passcodeDocRef, passcodeData, { merge: true })

    const docRef = doc(this.firestore, `users/${this.user.uid}`)
    const data = { passcodes: { } }
    data.passcodes[passcode.code] = 'redeemed'
    const userPromise = setDoc(docRef, data, { merge: true })

    //return promise.all([passcodePromise, userPromise]);

  }

  passcodeInvalid(passcode) {

    const passcodeDocRef = doc(this.firestore, `passcodes/${passcode.id}`)
    const passcodeData = { invalid: increment(1), lastInvalid: serverTimestamp()  }
    const passcodePromise = setDoc(passcodeDocRef, passcodeData, { merge: true })

    const docRef = doc(this.firestore, `users/${this.user.uid}`)
    const data = { passcodes: { } }
    data.passcodes[passcode.code] = 'invalid'
    const userPromise = setDoc(docRef, data, { merge: true })

    //return promise.all([passcodePromise, userPromise]);

  }

}
